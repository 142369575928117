var columns = [{
  title: "流水ID",
  dataIndex: "id",
  key: "id",
  width: '25%'
}, {
  title: "流水类型",
  dataIndex: "flowname",
  key: "flowname",
  width: '20%'
}, {
  title: "用户",
  dataIndex: "createdname",
  key: "createdname",
  scopedSlots: {
    customRender: "createdname"
  },
  width: '20%'
}, {
  title: "数量",
  dataIndex: "amount",
  key: "amount",
  width: '15%'
}, {
  title: "时间(UTC)",
  dataIndex: "createddatatimetimestamp",
  key: "createddatatimetimestamp",
  width: '20%',
  // sortOrder: 'descend',
  scopedSlots: {
    customRender: "createddatatimetimestamp"
  }
}];
export { columns };