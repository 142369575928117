var searchKeys = [{
  key: "userids",
  label: "用户ID",
  placeholder: "用户ID，多个一行一个",
  required: false,
  rules: [],
  textarea: true,
  trim: false,
  hidden: true
}, {
  key: "eventtype",
  label: "流水类型",
  required: false,
  rules: [],
  select: true,
  initialValue: undefined,
  placeholder: "流水类型",
  attrs: {
    allowClear: true
  }
}, {
  key: "date",
  label: "时间(UTC)",
  format: "YYYY-MM-DD HH:mm",
  placeholder: ["开始时间", "截止时间"],
  rules: [],
  dates: true
}, {
  key: "button",
  label: "",
  placeholder: "",
  button: true
}];
export { searchKeys };